<template>
  <div>
    <v-row v-if="wettkampf && sportler && !checkout" class="pa-0 ma-0">
      <v-col cols="12" class="ma-0 pa-0">
        <v-card
          :color="
            sportler.checkin
              ? sportler.checkin.verlassen.datum > timer.datum ||
                (sportler.checkin.verlassen.datum == timer.datum &&
                  sportler.checkin.verlassen.uhrzeit > timer.uhrzeit)
                ? template.colors.success
                : template.colors.danger
              : template.colors.blocks
          "
          :style="`${
            $vuetify.breakpoint.smAndDown ? 'min-height: 80vh;' : ''
          }color: ${
            sportler.checkin
              ? sportler.checkin.verlassen.datum > timer.datum ||
                (sportler.checkin.verlassen.datum == timer.datum &&
                  sportler.checkin.verlassen.uhrzeit > timer.uhrzeit)
                ? template.colors.success_text
                : template.colors.danger_text
              : template.colors.block_text
          };`"
          class="rounded-xl pb-0"
        >
          <v-row justify="center" class="px-4" style="height: 100%;">
            <v-col cols="12" align-self="start">
              <v-row justify="center" class="px-4">
                <v-col cols="auto" class="text-center" align-self="center">
                  <v-avatar tile v-if="publicdata.verein">
                    <v-img contain :src="publicdata.verein.verein.logo"></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="auto">
                  <h3 class="font-weight-light">
                    <small>{{ publicdata.verein.verein.name }}</small>
                  </h3>
                  <h2>
                    <span class="font-weight-bold">
                      {{ wettkampf.name }}
                    </span>
                  </h2>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" align-self="center">
              <v-row justify="center" class="mt-2 px-4">
                <v-col cols="12" class="text-center" align-self="center">
                  <small>
                    {{ sportler.person.aktivitaet }}
                  </small>
                  <h2>
                    {{ sportler.person.vorname }} {{ sportler.person.name }}
                  </h2>
                  <h1 v-if="sportler.startnummer">
                    {{ sportler.startnummer }}
                  </h1>
                </v-col>
              </v-row>
              <v-row justify="center" class="pa-0" v-if="sportler.checkin">
                <v-col
                  cols="12"
                  class="text-center"
                  v-if="
                (sportler.checkin.verlassen.datum < timer.datum || (sportler.checkin.verlassen.datum == timer.datum &&
                  sportler.checkin.verlassen.uhrzeit < timer.uhrzeit))
              "
                >
                  <h3>
                    Du bist seit
                    {{
                      sportler.checkin.verlassen.datum > timer.datum
                        ? 'dem ' +
                          parseDate(sportler.checkin.verlassen.datum) +
                          ' um '
                        : ''
                    }}{{ sportler.checkin.verlassen.uhrzeit }} Uhr ausgecheckt.
                  </h3>
                </v-col>
                <v-col
                  cols="12"
                  class="text-center"
                  v-if="
                    sportler.checkin.verlassen.datum > timer.datum ||
                    (sportler.checkin.verlassen.datum == timer.datum &&
                      sportler.checkin.verlassen.uhrzeit > timer.uhrzeit)
                  "
                >
                  <h3 class="mt-3 mb-5 font-weight-light">
                    Du bist seit
                    <span class="font-weight-bold">
                      {{ sportler.checkin.zutritt.uhrzeit }} Uhr
                    </span>
                    anwesend
                  </h3>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="10" class="my-5">
              <ggswitches
                :geimpft="false"
                :getestet="false"
                :genesen="false"
                :datum="false"
                :public="true"
                @valid="validate_ggg($event)"
                @change="get_ggg_data($event)"
              ></ggswitches>
            </v-col>
            <v-col cols="12" class="text-center" align-self="end">
              <v-btn
                :color="template.colors.primary"
                :light="
                  wettkampf.beginn.datum > timer.datum ||
                  wettkampf.ende.datum < timer.datum
                    ? testpflicht
                      ? template.isDark(template.colors.primary)
                      : !template.isDark(template.colors.primary)
                    : template.isDark(template.colors.primary)
                "
                :dark="
                  (wettkampf.beginn.datum <= timer.datum &&
                    wettkampf.ende.datum >= timer.datum) ||
                  tespflicht
                    ? testpflicht
                      ? !template.isDark(template.colors.primary)
                      : template.isDark(template.colors.primary)
                    : !template.isDark(template.colors.primary)
                "
                rounded
                x-large
                :loading="loading"
                :disabled="
                  wettkampf.beginn.datum > timer.datum ||
                  wettkampf.ende.datum < timer.datum ||
                  testpflicht ||
                  !ggg_valid
                "
                @click="check_in()"
                v-if="!sportler.checkin"
              >
                <v-icon class="mr-2">mdi-account-check</v-icon>
                Check In
              </v-btn>
              <h4 class="mt-4" v-if="wettkampf.beginn.datum > timer.datum">
                Ein CheckIn ist erst am
                {{ parseDate(wettkampf.beginn.datum) }} möglich
              </h4>
              <v-btn
                :color="template.colors.success_text"
                :light="
                  wettkampf.beginn.datum > timer.datum ||
                  wettkampf.ende.datum < timer.datum
                    ? template.isDark(template.colors.success_text)
                    : !template.isDark(template.colors.success_text)
                "
                :dark="
                  wettkampf.beginn.datum <= timer.datum &&
                  wettkampf.ende.datum >= timer.datum
                    ? template.isDark(template.colors.success_text)
                    : !template.isDark(template.colors.success_text)
                "
                :disabled="
                  wettkampf.beginn.datum > timer.datum ||
                  wettkampf.ende.datum < timer.datum
                "
                rounded
                x-large
                v-if="sportler.checkin"
                @click="check_out()"
              >
                Check Out
                <v-icon class="ml-2">mdi-logout-variant</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="checkout" style="height: 70vh;">
      <v-col cols="11">
        <h2 class="white--text">Veranstaltung verlassen</h2>
        <p>
          Du hast die Veranstaltung verlassen und deine Zeit wurde erfolgreich
          gespeichert. Danke für deinen Besuch!
        </p>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      v-if="!wettkampf && !sportler && !checkout"
      style="height: 70vh;"
    >
      <v-col cols="11" align-self="center" class="text-center">
        <v-progress-circular size="60" indeterminate></v-progress-circular>
        <h3>Anmeldung wird geladen...</h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from 'firebase'
import store from '../../store'
import logout from '../../auth/auth'
import ggswitches from './2G-Regel/switches.vue'

export default {
  name: 'Veranstaltung',
  components: {
    ggswitches,
  },
  data() {
    return {
      valid: false,
      checkout: false,
      loading: false,
      checkoutzeit: '',
      aktuellezeit: '',
      aktuellesdatum: '',
      zuschauer: '',
      sportler: '',
      wettkampf: '',
      modal2: false,
      ggg: {
        getestet: false,
        geimpft: false,
        genesen: false,
      },
      ggg_valid: false,
    }
  },
  computed: {
    ...mapGetters({
      publicdata: 'publicdata',
      template: 'template',
      timer: 'timer',
    }),
    testpflicht() {
      if (this.publicdata.verein) {
        if (this.publicdata.verein.hygienekonzept) {
          if (this.publicdata.verein.hygienekonzept.testpflicht) {
            if (!this.ggg.getestet && !this.ggg.geimpft && !this.ggg.genesen) {
              return true
            }
          }
        }
      }
      return false
    },
    testpflicht_required() {
      if (this.publicdata.verein && this.publicdata.verein.hygienekonzept) {
        if (tthis.publicdata.verein.hygienekonzept.testpflicht) {
          if (!this.publicdata.verein.hygienekonzept.gg_regel) {
            return true
          }
        }
      }
      return false
    },
    gg_regel() {
      if (this.publicdata.verein && this.publicdata.verein.hygienekonzept) {
        if (this.publicdata.verein.hygienekonzept.gg_regel) {
          return true
        }
      }
      return false
    },
    ggplus_regel() {
      if (this.publicdata.verein && this.publicdata.verein.hygienekonzept) {
        if (this.publicdata.verein.hygienekonzept.ggplus_regel) {
          return true
        }
      }
      return false
    },
  },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.SESSION)
          .then(() => {
            return firebase.auth().signInAnonymously()
          })
      } else {
        store.dispatch('setPublic', {
          name: this.$route.params.verein,
          sportstaette: this.$route.params.sportstaette,
        })
        setTimeout(() => {
          this.load_anmeldung()
        }, 50)
        setTimeout(this.setTime(), 500)
      }
    })
  },
  methods: {
    validate_ggg(daten) {
      this.ggg_valid = daten
    },
    get_ggg_data(daten) {
      if (daten) {
        this.ggg = {
          getestet: daten.ggg.getestet || false,
          geimpft: daten.ggg.geimpft || false,
          genesen: daten.ggg.genesen || false,
          geburtsdatum: daten.geburtsdatum || false,
        }
      }
    },
    load_anmeldung() {
      if (
        this.publicdata.verein.id &&
        this.$route.params.wettkampf &&
        this.$route.params.anmeldung
      ) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.publicdata.verein.id)
          .collection('Veranstaltung')
          .doc(this.$route.params.wettkampf)
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.wettkampf = doc.data()
              this.wettkampf.id = doc.id
              doc.ref
                .collection('Anmeldung')
                .doc(this.$route.params.anmeldung)
                .get()
                .then((anmeldung) => {
                  this.sportler = anmeldung.data()
                  this.sportler.id = anmeldung.id
                })
            }
          })
      } else {
        setTimeout(() => {
          this.load_anmeldung()
        }, 100)
      }
    },
    setTime() {
      var today = new Date()
      this.aktuellezeit =
        this.addNull(parseInt(today.getHours())) +
        ':' +
        this.addNull(parseInt(today.getMinutes()))
      this.aktuellesdatum =
        today.getFullYear() +
        '-' +
        this.addNull(parseInt(today.getMonth()) + 1) +
        '-' +
        this.addNull(parseInt(today.getDate()))
      setTimeout(() => {
        this.setTime()
      }, 30000)
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    parseDate1(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.'
    },
    addNull(num) {
      if (num > 10) {
        return num
      }
      return '0' + num
    },
    async check_in() {
      this.loading = true

      var sportstaette = this.wettkampf.sportstaette
        ? this.wettkampf.sportstaette
        : ''

      this.publicdata.verein.sportstaetten.forEach((place) => {
        if (place.name == this.wettkampf.sportstaette) {
          sportstaette = place
        }
      })

      var zutritt = new Zeit()
      var verlassen = new Zeit(
        this.wettkampf.ende.datum,
        this.wettkampf.ende.uhrzeit,
      )

      var daten = {
        person: {
          vorname: this.sportler.person.vorname
            ? this.sportler.person.vorname
            : '',
          name: this.sportler.person.name ? this.sportler.person.name : '',
          adresse: this.sportler.person.adresse
            ? this.sportler.person.adresse
            : '',
          plz: this.sportler.person.plz ? this.sportler.person.plz : '',
          ort: this.sportler.person.ort ? this.sportler.person.ort : '',
          telefon: this.sportler.person.telefon
            ? this.sportler.person.telefon
            : '',
        },
        ggg: this.ggg || '',
        zutritt: zutritt.toJSON(),
        weitere_personen: [],
        sportstaette: sportstaette ? sportstaette : '',
        veranstaltung: this.wettkampf ? this.wettkampf : '',
        verlassen: verlassen.toJSON(),
        aktivitaet: this.sportler.person.aktivitaet
          ? this.sportler.person.aktivitaet
          : '',
      }

      await firebase
        .firestore()
        .collection('User')
        .doc(this.publicdata.verein.id)
        .collection('Anwesenheit')
        .add(daten)
        .then((docRef) => {
          firebase
            .firestore()
            .collection('User')
            .doc(this.publicdata.verein.id)
            .collection('Veranstaltung')
            .doc(this.$route.params.wettkampf)
            .collection('Anmeldung')
            .doc(this.$route.params.anmeldung)
            .update({
              checkin: {
                zutritt: daten.zutritt,
                verlassen: daten.verlassen,
                id: docRef.id,
              },
            })
            .then(() => {
              this.sportler.checkin = {
                zutritt: daten.zutritt,
                verlassen: daten.verlassen,
                id: docRef.id,
              }
            })
        })
        .catch((error) => {
          this.loading = false
        })
    },
    check_out() {
      if (this.sportler.checkin) {
        var verlassen = new Zeit()
        firebase
          .firestore()
          .collection('User')
          .doc(this.publicdata.verein.id)
          .collection('Anwesenheit')
          .doc(this.sportler.checkin.id)
          .update({
            verlassen: verlassen.toJSON(),
            checkout: true,
          })
          .then(() => {
            firebase
              .firestore()
              .collection('User')
              .doc(this.publicdata.verein.id)
              .collection('Veranstaltung')
              .doc(this.$route.params.wettkampf)
              .collection('Anmeldung')
              .doc(this.$route.params.anmeldung)
              .update({
                checkin: '',
              })
              .then(() => {
                this.sportler.checkin = ''
              })
          })
      }
    },
    later_check_out() {
      if (this.publicdata.veranstaltung.id != 'andere') {
        firebase
          .firestore()
          .collection('User')
          .doc(this.publicdata.verein.id)
          .collection('Veranstaltung')
          .doc(this.publicdata.veranstaltung.id)
          .collection('Anwesenheit')
          .doc(this.$route.params.zuschauer)
          .update({
            verlassen: this.checkoutzeit,
          })
          .then(() => {
            this.checkout = true
            logout.logout()
          })
      } else {
        firebase
          .firestore()
          .collection('User')
          .doc(this.publicdata.verein.id)
          .collection('Anwesenheit')
          .doc(this.$route.params.zuschauer)
          .update({
            verlassen: this.checkoutzeit,
          })
          .then(() => {
            this.checkout = true
            logout.logout()
          })
      }
    },
  },
}
</script>
