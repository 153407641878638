var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.wettkampf && _vm.sportler && !_vm.checkout)?_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-xl pb-0",style:(((_vm.$vuetify.breakpoint.smAndDown ? 'min-height: 80vh;' : '') + "color: " + (_vm.sportler.checkin
            ? _vm.sportler.checkin.verlassen.datum > _vm.timer.datum ||
              (_vm.sportler.checkin.verlassen.datum == _vm.timer.datum &&
                _vm.sportler.checkin.verlassen.uhrzeit > _vm.timer.uhrzeit)
              ? _vm.template.colors.success_text
              : _vm.template.colors.danger_text
            : _vm.template.colors.block_text) + ";")),attrs:{"color":_vm.sportler.checkin
            ? _vm.sportler.checkin.verlassen.datum > _vm.timer.datum ||
              (_vm.sportler.checkin.verlassen.datum == _vm.timer.datum &&
                _vm.sportler.checkin.verlassen.uhrzeit > _vm.timer.uhrzeit)
              ? _vm.template.colors.success
              : _vm.template.colors.danger
            : _vm.template.colors.blocks}},[_c('v-row',{staticClass:"px-4",staticStyle:{"height":"100%"},attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align-self":"start"}},[_c('v-row',{staticClass:"px-4",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"auto","align-self":"center"}},[(_vm.publicdata.verein)?_c('v-avatar',{attrs:{"tile":""}},[_c('v-img',{attrs:{"contain":"","src":_vm.publicdata.verein.verein.logo}})],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('h3',{staticClass:"font-weight-light"},[_c('small',[_vm._v(_vm._s(_vm.publicdata.verein.verein.name))])]),_c('h2',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.wettkampf.name)+" ")])])])],1)],1),_c('v-col',{attrs:{"cols":"12","align-self":"center"}},[_c('v-row',{staticClass:"mt-2 px-4",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","align-self":"center"}},[_c('small',[_vm._v(" "+_vm._s(_vm.sportler.person.aktivitaet)+" ")]),_c('h2',[_vm._v(" "+_vm._s(_vm.sportler.person.vorname)+" "+_vm._s(_vm.sportler.person.name)+" ")]),(_vm.sportler.startnummer)?_c('h1',[_vm._v(" "+_vm._s(_vm.sportler.startnummer)+" ")]):_vm._e()])],1),(_vm.sportler.checkin)?_c('v-row',{staticClass:"pa-0",attrs:{"justify":"center"}},[(
              (_vm.sportler.checkin.verlassen.datum < _vm.timer.datum || (_vm.sportler.checkin.verlassen.datum == _vm.timer.datum &&
                _vm.sportler.checkin.verlassen.uhrzeit < _vm.timer.uhrzeit))
            )?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h3',[_vm._v(" Du bist seit "+_vm._s(_vm.sportler.checkin.verlassen.datum > _vm.timer.datum ? 'dem ' + _vm.parseDate(_vm.sportler.checkin.verlassen.datum) + ' um ' : '')+_vm._s(_vm.sportler.checkin.verlassen.uhrzeit)+" Uhr ausgecheckt. ")])]):_vm._e(),(
                  _vm.sportler.checkin.verlassen.datum > _vm.timer.datum ||
                  (_vm.sportler.checkin.verlassen.datum == _vm.timer.datum &&
                    _vm.sportler.checkin.verlassen.uhrzeit > _vm.timer.uhrzeit)
                )?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h3',{staticClass:"mt-3 mb-5 font-weight-light"},[_vm._v(" Du bist seit "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.sportler.checkin.zutritt.uhrzeit)+" Uhr ")]),_vm._v(" anwesend ")])]):_vm._e()],1):_vm._e()],1),_c('v-col',{staticClass:"my-5",attrs:{"cols":"10"}},[_c('ggswitches',{attrs:{"geimpft":false,"getestet":false,"genesen":false,"datum":false,"public":true},on:{"valid":function($event){return _vm.validate_ggg($event)},"change":function($event){return _vm.get_ggg_data($event)}}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","align-self":"end"}},[(!_vm.sportler.checkin)?_c('v-btn',{attrs:{"color":_vm.template.colors.primary,"light":_vm.wettkampf.beginn.datum > _vm.timer.datum ||
                _vm.wettkampf.ende.datum < _vm.timer.datum
                  ? _vm.testpflicht
                    ? _vm.template.isDark(_vm.template.colors.primary)
                    : !_vm.template.isDark(_vm.template.colors.primary)
                  : _vm.template.isDark(_vm.template.colors.primary),"dark":(_vm.wettkampf.beginn.datum <= _vm.timer.datum &&
                  _vm.wettkampf.ende.datum >= _vm.timer.datum) ||
                _vm.tespflicht
                  ? _vm.testpflicht
                    ? !_vm.template.isDark(_vm.template.colors.primary)
                    : _vm.template.isDark(_vm.template.colors.primary)
                  : !_vm.template.isDark(_vm.template.colors.primary),"rounded":"","x-large":"","loading":_vm.loading,"disabled":_vm.wettkampf.beginn.datum > _vm.timer.datum ||
                _vm.wettkampf.ende.datum < _vm.timer.datum ||
                _vm.testpflicht ||
                !_vm.ggg_valid},on:{"click":function($event){return _vm.check_in()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-check")]),_vm._v(" Check In ")],1):_vm._e(),(_vm.wettkampf.beginn.datum > _vm.timer.datum)?_c('h4',{staticClass:"mt-4"},[_vm._v(" Ein CheckIn ist erst am "+_vm._s(_vm.parseDate(_vm.wettkampf.beginn.datum))+" möglich ")]):_vm._e(),(_vm.sportler.checkin)?_c('v-btn',{attrs:{"color":_vm.template.colors.success_text,"light":_vm.wettkampf.beginn.datum > _vm.timer.datum ||
                _vm.wettkampf.ende.datum < _vm.timer.datum
                  ? _vm.template.isDark(_vm.template.colors.success_text)
                  : !_vm.template.isDark(_vm.template.colors.success_text),"dark":_vm.wettkampf.beginn.datum <= _vm.timer.datum &&
                _vm.wettkampf.ende.datum >= _vm.timer.datum
                  ? _vm.template.isDark(_vm.template.colors.success_text)
                  : !_vm.template.isDark(_vm.template.colors.success_text),"disabled":_vm.wettkampf.beginn.datum > _vm.timer.datum ||
                _vm.wettkampf.ende.datum < _vm.timer.datum,"rounded":"","x-large":""},on:{"click":function($event){return _vm.check_out()}}},[_vm._v(" Check Out "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-logout-variant")])],1):_vm._e()],1)],1)],1)],1)],1):_vm._e(),(_vm.checkout)?_c('v-row',{staticStyle:{"height":"70vh"},attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('h2',{staticClass:"white--text"},[_vm._v("Veranstaltung verlassen")]),_c('p',[_vm._v(" Du hast die Veranstaltung verlassen und deine Zeit wurde erfolgreich gespeichert. Danke für deinen Besuch! ")])])],1):_vm._e(),(!_vm.wettkampf && !_vm.sportler && !_vm.checkout)?_c('v-row',{staticStyle:{"height":"70vh"},attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"11","align-self":"center"}},[_c('v-progress-circular',{attrs:{"size":"60","indeterminate":""}}),_c('h3',[_vm._v("Anmeldung wird geladen...")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }